<template>
  <v-row>
    <v-col cols="7">
      <general-card class="pa-5 mb-3">
        <v-row>
          <v-col>
            <h5 class="mb-2">Ad name</h5>
            <v-text-field
              v-model="updated_ad.name"
              single-line
              hide-details
              label="Ad name"
              outlined
              rounded
              dense
              height="30px"
              color="#3371E2"
            ></v-text-field>
          </v-col>

          <v-col>
            <h6 class="mb-2">Status</h6>
            <v-select
              :items="ad_statuses"
              label="Set status"
              outlined
              dense
              v-model="updated_ad.status"
            ></v-select>
          </v-col>
        </v-row>
      </general-card>
      <general-card class="pa-5 mb-3">
        <v-row>
          <v-col>
            <h6>
              Updating Of Creative Elements such as Images, Videos and their
              placements resets their engagement metrics. Kindly pause or delete
              the Ad and recreate a new one with the right details.
            </h6>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn class="primary float-left" @click="updateAd(updated_ad)">
              Update Ad</v-btn
            >
            <v-btn class="red--text float-right" @click="deleteAd(facebook_ad)">
              Delete Ad</v-btn
            >
          </v-col>
        </v-row>
      </general-card>
    </v-col>
  </v-row>
</template>
<script>
import GeneralCard from "../../../components/Layouts/Cards/GeneralCard.vue";

export default {
  data: () => ({
    ad_statuses: ["ACTIVE", "PAUSED", "DELETED", "ARCHIVED"],
    updated_ad: {
      name: "",
      status: "",
    },
  }),
  async mounted() {
    this.ad_id = this.$route.params.id;

    //Prepopulate
    this.updated_ad.name = this.facebook_ad.name;
    this.updated_ad.status = this.facebook_ad.status;
    this.updated_ad.ad_id = this.$route.params.id;
    this.updated_ad.adAccountId = this.facebook_ad.account_id;

    // Get Ad Details
    try {
      await this.$store.dispatch("facebook/getFacebookAd", {
        adAccountId: this.adAccountId,
        ad_id: this.ad_id,
      });
    } catch (error) {
this.error = error
    }

    // //Get Ad Creative
    // try {
    //   await this.$store.dispatch("facebook/getFacebookCreative", {
    //     adAccountId: this.adAccountId,
    //     creativeId: this.facebook_ad.creative.id,
    //   });
    // } catch (error) {
    //   this.$notify({
    //     title: "Sorry, there was a problem getting Creative Data. Please try again later",
    //     group: "errors",
    //   });
    // }
  },
  methods: {
    async updateAd(updated_ad) {
      /////// ADS CAN ONLY BE UPDATED ONCE IN 72 HOURS
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("facebook/editFacebookAd", updated_ad);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Ad successfully updated!",
          group: "success",
        });
        this.$router.go(-1);
      } catch (error) {
        this.$notify({
        title: "Sorry, there was a problem updating the Ad. Please try again later",
        group: "errors",
      });
        this.$store.commit("makingApiRequest", false);
      }
    },
    async deleteAd(facebook_ad) {
      const ids = {
        adAccountId: facebook_ad.account_id,
        ad_id: facebook_ad.id,
      };
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("deleteFacebookAd", ids);
      this.$store.commit("makingApiRequest", false);
      this.$notify({
        title: "Ad successfully deleted!",
        group: "success",
      });
      this.$router.push(`/facebook/ads`);
    },
  },
  components: {
    GeneralCard,
  },
  computed: {
    facebook_ad() {
      return this.$store.state.facebook.facebook_ad;
    },
    facebook_creative() {
      return this.$store.state.facebook.facebook_creative;
    },
    ad_image() {
      return this.$store.state.facebook.ad_image[0];
    },
  },
};
</script>
<style>
/* #visuals .theme--light.v-divider {
  border-color: rgba(235, 235, 235, 0.3);
}

.v-input__control {
  height: 40px;
}

#calendar .v-input__icon {
  height: 24px !important;
  width: 0;
}

.no-space label {
  font-size: 0.7rem !important;
}

.no-space .v-icon.v-icon--dense {
  font-size: 0.7rem !important;
  color: rgba(112, 112, 112, 0.5) !important;
}

.row + .row {
  margin-top: 0;
} */
</style>
